import React from 'react';
import styled from 'styled-components';
import { Button, Header, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ImageError from '../../assets/404_403.jpg';

const FlexContainer = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-bottom: 1.5em;
`;

const Error404 = () => {
	return (
		<FlexContainer>
			<div style={{ margin: '.5em' }}>
				<Image src={ImageError} />
			</div>
			<div style={{ margin: '.5em' }}>
				<Header as="h1">Eror 404</Header>
			</div>
			<div style={{ margin: '.5em', textAlign: 'center' }}>
				Maaf, alamat web yang Anda masukan tidak dapat ditemukan.
			</div>
			<div>
				Klik,{' '}
				<span>
					{
						<Link to="/">
							<Button
								primary
								size="tiny"
								icon="arrow left"
							/>
						</Link>
					}
				</span>{' '}
				untuk kembali ke halaman utama.
			</div>
		</FlexContainer>
	);
};

export default Error404;
