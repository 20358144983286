import Loadable from '../../components/Common/Loadable';

const DaftarMurid = Loadable(() => import('./DaftarMurid/DaftarMurid'));
const DetilAkunMurid = Loadable(() => import('./DaftarMurid/DetilAkunMurid'));
const DaftarKelas = Loadable(() => import('./DaftarKelas/DaftarKelas'));
const DetilKelas = Loadable(() => import('./DaftarKelas/DetilKelas'));
const TambahKelas = Loadable(() => import('./DaftarKelas/TambahKelas'));
const DetilAkunKaryawan = Loadable(
	() => import('./DaftarKaryawan/DetilAkunKaryawan'),
);
const DaftarKaryawan = Loadable(
	() => import('./DaftarKaryawan/DaftarKaryawan'),
);
const StatistikPenggunaan = Loadable(
	() => import('./StatistikPenggunaan/StatistikPenggunaan'),
);
const DaftarMapel = Loadable(() => import('./DaftarMapel/DaftarMapel'));
const DetilMapel = Loadable(() => import('./DaftarMapel/DetilMapel'));
const IdentitasSekolah = Loadable(
	() => import('./IdentitasSekolah/IdentitasSekolah'),
);

export default {
	DaftarMurid,
	DetilAkunMurid,
	DaftarKaryawan,
	DetilAkunKaryawan,
	StatistikPenggunaan,
	DaftarMapel,
	DetilMapel,
	IdentitasSekolah,
	DaftarKelas,
	TambahKelas,
	DetilKelas,
};
