import _ from 'lodash';

import murid from './murid';
import karyawan from './karyawan';

const routes = [
	...murid.map((o) => _.assign({ list_model: ['murid'] }, o)),
	...karyawan.map((o) => _.assign({ list_model: ['karyawan'] }, o)),
];
export default routes;
