import { Icon, Label } from 'semantic-ui-react';

export const LIST_TINGKAT = [
	{ key: 'TINGKAT_I', value: 'I', text: 'Tingkat I', jenjang: ['SD', 'UMUM'] },
	{
		key: 'TINGKAT_II',
		value: 'II',
		text: 'Tingkat II',
		jenjang: ['SD', 'UMUM'],
	},
	{
		key: 'TINGKAT_III',
		value: 'III',
		text: 'Tingkat III',
		jenjang: ['SD', 'UMUM'],
	},
	{
		key: 'TINGKAT_IV',
		value: 'IV',
		text: 'Tingkat IV',
		jenjang: ['SD', 'UMUM'],
	},
	{ key: 'TINGKAT_V', value: 'V', text: 'Tingkat V', jenjang: ['SD', 'UMUM'] },
	{
		key: 'TINGKAT_VI',
		value: 'VI',
		text: 'Tingkat VI',
		jenjang: ['SD', 'UMUM'],
	},

	{
		key: 'TINGKAT_VII',
		value: 'VII',
		text: 'Tingkat VII',
		jenjang: ['SMP', 'UMUM'],
	},
	{
		key: 'TINGKAT_VIII',
		value: 'VIII',
		text: 'Tingkat VIII',
		jenjang: ['SMP', 'UMUM'],
	},
	{
		key: 'TINGKAT_IX',
		value: 'IX',
		text: 'Tingkat IX',
		jenjang: ['SMP', 'UMUM'],
	},

	{
		key: 'TINGKAT_X',
		value: 'X',
		text: 'Tingkat X',
		jenjang: ['SMA', 'SMK', 'UMUM'],
	},
	{
		key: 'TINGKAT_XI',
		value: 'XI',
		text: 'Tingkat XI',
		jenjang: ['SMA', 'SMK', 'UMUM'],
	},
	{
		key: 'TINGKAT_XII',
		value: 'XII',
		text: 'Tingkat XII',
		jenjang: ['SMA', 'SMK', 'UMUM'],
	},
];

export const LIST_PROGRAM = [
	{
		key: 'UMUM',
		value: 'UMUM',
		text: 'UMUM',
		jenjang: ['SD', 'SMP', 'SMA', 'SMK', 'UMUM'],
	},
	{ key: 'IPS', value: 'IPS', text: 'IPS', jenjang: ['SMA', 'SMK', 'UMUM'] },
	{ key: 'MIPA', value: 'MIPA', text: 'MIPA', jenjang: ['SMA', 'SMK', 'UMUM'] },
];

export const LIST_JENIS_SOAL = [
	{ key: 'PILIHAN_GANDA', value: 'PILIHAN_GANDA', text: 'Pilihan Ganda' },
	{ key: 'TEKS_BEBAS', value: 'TEKS_BEBAS', text: 'Teks Bebas' },
	{ key: 'ANGKA_SAJA', value: 'ANGKA_SAJA', text: 'Angka Saja' },
	{ key: 'ISIAN_PANJANG', value: 'ISIAN_PANJANG', text: 'Isian Panjang' },
];

export const LIST_JENJANG = [
	{ key: 'UMUM', value: 'UMUM', text: 'UMUM' },
	{ key: 'SD', value: 'SD', text: 'SD' },
	{ key: 'SMP', value: 'SMP', text: 'SMP' },
	{ key: 'SMA', value: 'SMA', text: 'SMA' },
	{ key: 'SMK', value: 'SMK', text: 'SMK' },
];

export const LIST_JENIS_JAWABAN = [
	{ key: 'PILIHAN_GANDA', value: 'PILIHAN_GANDA', text: 'Pilihan Ganda' },
	{ key: 'ISIAN_SINGKAT', value: 'ISIAN_SINGKAT', text: 'Isian Singkat' },
	{ key: 'URAIAN', value: 'URAIAN', text: 'Uraian' },
];

export const LIST_JENIS_AKSES = [
	{
		key: 'PRIBADI',
		value: 'PRIBADI',
		text: (
			<span>
				<Label color="grey">
					<Icon name="user" />
					Pribadi
				</Label>
			</span>
		),
	},
	{
		key: 'UMUM',
		value: 'UMUM',
		text: (
			<span>
				<Label color="teal">
					<Icon name="users" />
					Umum
				</Label>
			</span>
		),
	},
];

export const MAPEL_CAMPUR = 'CAMPURAN';

export const PRECISION = 2;
export const SESI_MULAI = 'MULAI';
export const SESI_JEDA = 'JEDA';
export const SESI_SELESAI = 'SELESAI';
export const SESI_BELUM_MULAI = 'BELUM_MULAI';
export const SESI_STATUS = ['BELUM_MULAI', SESI_MULAI, SESI_JEDA, SESI_SELESAI];
export const FETCH_INTERVAL = 8000;
export const CONTACT_IMPULS = '081228800048';
export const CONTACT_IMPULS_62 = '6281228800048';

export const PLATFORM_STATUS_MAINTENANCE = 'MAINTENANCE';
export const PLATFORM_STATUS_LIVE = 'LIVE';
export const THEME_COLOR = '#00B5AD';
export const BLUE_COLOR = '#1D62DA';

export const LJD_START = 'START';
export const LJD_EXTRA = 'EXTRA';
export const LJD_FINISH = 'FINISH';
