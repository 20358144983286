import _ from 'lodash';
import { Loader, Dimmer } from 'semantic-ui-react';
import { Redirect, Switch, Route } from 'react-router-dom';
import React, { useContext, useState } from 'react';

import { logout } from '../fetcher';
import { UserContext } from '../context';

import HeaderMenu from '../collections/HeaderMenu/HeaderMenu';
import BaseContentLayout from '../layouts/BaseContentLayout/BaseContentLayout';
import Error404 from '../collections/Error/404';

const Dummy = () => <Error404 />;

const Dashboard = ({ location, history }) => {
	const session = useContext(UserContext);
	const [loading, setLoading] = useState(false);

	const { list_menu = [], list_route = [], user } = session;

	const logoSekolah = _.get(session, 'sekolah.logo', '');
	const onMenuClick = async (e, data) => {
		const value = _.get(data, 'value', '/');

		if (value === 'logout') {
			setLoading(true);
			await logout();
			await session.checkUser();
		} else {
			return history.push(value);
		}
	};
	const redirection = location.pathname
		? `?redirection=${encodeURIComponent(location.pathname)}`
		: '';
	if (!session.is_login) {
		return <Redirect to={`/login${redirection}`} />;
	}

	const uri = _.get(location, 'pathname', '');
	const nama = _.get(user, 'nama', '');
	const default_to = _.isEqual(session.model, 'murid')
		? _.get(list_menu, [0, 'parent', 'to'], '/ujian')
		: _.get(list_menu, [0, 'parent', 'to'], '/profil');
	const roleUser = _.isEqual(session.model, 'murid')
		? _.get(session, 'user.nis', '-')
		: _.includes(
					_.get(session, 'roles', []).map((o) => o.nama),
					'sistem',
			  )
			? 'Admin Sistem'
			: 'Karyawan';
	if (session.is_login && _.isEqual(uri, '/')) {
		return <Redirect to={default_to} />;
	}

	window.scrollTo(0, 0);

	return (
		<div>
			<Dimmer active={loading}>
				<Loader active={loading} />
			</Dimmer>
			<BaseContentLayout listRoute={list_route}>
				<div>
					<HeaderMenu
						logoSekolah={logoSekolah}
						name={nama}
						roleUser={roleUser}
						onActionClick={onMenuClick}
						listMenu={list_menu}
					/>
				</div>
				<Switch>
					{list_route.map((o) => (
						<Route
							key={_.snakeCase(o.path)}
							exact
							{...o}
						/>
					))}
					<Route
						key="DEFAULT"
						path="/">
						<Dummy />
					</Route>
				</Switch>
			</BaseContentLayout>
		</div>
	);
};

export default Dashboard;
