import React from 'react';
import styled from 'styled-components';
import { Button, Header, Image } from 'semantic-ui-react';
import ImageMaintenance from '../../assets/maintenance.jpg';
import { CONTACT_IMPULS, CONTACT_IMPULS_62 } from '../../helpers/constant';
import moment from 'moment';

const FlexContainer = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-bottom: 1.5em;
`;

const Maintenance = (props) => {
	const pretty = moment(props.tanggal).format('DD MMMM YYYY, HH:mm');
	return (
		<FlexContainer>
			<div style={{ margin: '.5em' }}>
				<Image src={ImageMaintenance} />
			</div>
			<div style={{ margin: '.5em', textAlign: 'center' }}>
				<Header as="h1">ICBT Sedang Dalam Pemeliharaan.</Header>
			</div>
			<div style={{ margin: '.5em', textAlign: 'center' }}>
				Mohon maaf saat ini kami sedang melakukan pemeliharaan sistem yang
				diperkirakan selesai pada {pretty}.
			</div>
			<div style={{ margin: '.5em', textAlign: 'center' }}>
				Hubungi{' '}
				{
					<a
						href={`https://wa.me/${CONTACT_IMPULS_62}`}
						target="_blank">
						{CONTACT_IMPULS}
					</a>
				}{' '}
				untuk mengetahui status terbaru.
			</div>
			<div>
				Klik,{' '}
				<span>
					{
						<Button
							primary
							size="tiny"
							icon="sync"
							onClick={() => window.location.reload()}
						/>
					}
				</span>{' '}
				untuk mengetahui status pemeliharaan.
			</div>
		</FlexContainer>
	);
};

export default Maintenance;
