import Media from 'react-media';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import React, { Component } from 'react';
import { Header, Divider, Form, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom/cjs/react-router-dom';

import Footer from '../../components/Footer/Footer';
import LoginForm from './LoginForm';
import ForgetForm from './ForgetForm';

import { UserContext } from '../../context';
import { BACKGROUND, BACKGROUND_MINI } from '../../config';

import playStoreLogo from '../../assets/google-play-badge.png';
import appStoreLogo from '../../assets/app-store-badge.png';

const Layout = styled.div`
	position: relative;
	background: url(${BACKGROUND}) no-repeat center center fixed;
	min-width: 100%;
	min-height: 100vh;
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-size: cover;

	@media only screen and (max-width: 768px) {
		background: url(${BACKGROUND_MINI}) no-repeat center center fixed;
		background-size: cover;
		grid-template-rows: auto 1fr auto;
		grid-template-columns: 1fr;
		grid-gap: 1em;
		position: relative;
		padding: 1em;
	}
`;

const Left = styled.div`
	diplay: flex;
	flex-direction: row;
	color: white;
	max-width: 400px;
	align-self: center;
	justify-self: center;
	@media only screen and (max-width: 768px) {
	}
`;
const Right = styled.div`
	diplay: flex;
	flex-direction: row;
	color: white;
	justify-self: center;
	align-self: center;
	width: 100%;

	@media only screen and (max-width: 768px) {
		max-width: 363px;
	}
`;

const MainHolder = styled.div`
	display: flex;
	padding: 1.5rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: white;
	border-radius: 6px;
	flex-direction: column;
	position: responsive;

	@media only screen and (max-width: 768px) {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		max-width: 363px;
	}
`;

const Brand = styled.div`
	display: flex;
	position: absolute;
	bottom: 2em;
	left: 2em;
	flex-direction: row;
	align-items: flex-end;
	@media only screen and (max-width: 768px) {
		position: relative;
		bottom: 0;
		left: 0;
	}
`;

const DEFAULT_TITLE = 'Impuls Computer Based Test (v2.1.0)';

const Suggest = () => (
	<div>
		<Message>
			<Header
				as="h5"
				color="teal"
				style={{ fontWeight: 'normal' }}>
				Gunakan <b>Google Chrome/ Mozilla Firefox</b> untuk mendapatkan
				pengalaman terbaik.
			</Header>
		</Message>
	</div>
);
class LoginOpen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 'LOGIN',
			kode_sekolah: '',
			formStatusKode: {},
			isLoading: false,
		};

		this.handleStatus = this.handleStatus.bind(this);
		this.handleKodeSekolah = this.handleKodeSekolah.bind(this);
		this.handleValidasiKodeSekolah = this.handleValidasiKodeSekolah.bind(this);
	}

	handleStatus(value) {
		this.setState({ status: value || '' });
	}
	handleKodeSekolah(e, { value }) {
		this.setState({ kode_sekolah: value || '', formStatusKode: {} });
	}

	handleValidasiKodeSekolah(formStatusKode, isLoading) {
		this.setState({ formStatusKode, isLoading });
	}

	render() {
		const { status, formStatusKode, isLoading } = this.state;
		const { is_login } = this.context;
		document.title = DEFAULT_TITLE;

		if (is_login) {
			return <Redirect to="/" />;
		}
		return (
			<Layout>
				<Media query={{ maxWidth: 768 }}>
					{(v) =>
						v ? (
							<>
								<Left style={{ paddingTop: '4em' }}>
									<h2 style={{ margin: 0 }}>Selamat datang di</h2>
									<h1 style={{ margin: 0 }}>
										Impuls Computer Based Test (ICBT)
									</h1>
									<Divider />
									<div style={{ fontWeight: 500 }}>
										{'Informasi terbaru fitur ICBT dapat dibaca '}
										<Link
											style={{
												color: '#ffffff',
												fontWeight: 800,
												textDecoration: 'underline',
											}}
											onClick={() =>
												window.open('https://impuls.id/pos/kategori/icbt')
											}>
											di sini.
										</Link>
									</div>
									<Divider />
									<div style={{ fontWeight: 500 }}>
										Khusus peserta bisa mengerjakan memalui aplikasi ICBT Khusus
										Peserta
									</div>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'start',
											gap: '1rem',
											marginTop: '1rem',
										}}>
										<Link
											onClick={() =>
												window.open(
													'https://play.google.com/store/apps/details?id=com.impuls.icbt',
												)
											}>
											<img
												src={playStoreLogo}
												alt="playstore-logo"
												style={{ height: '35px' }}
											/>
										</Link>
										<Link
											onClick={() =>
												window.open(
													'https://apps.apple.com/id/app/icbt-untuk-peseta/id6499256814',
												)
											}>
											<img
												src={appStoreLogo}
												alt="playstore-logo"
												style={{ height: '35px' }}
											/>
										</Link>
									</div>
								</Left>
								<Right>
									<MainHolder>
										{status === 'FORGET' && (
											<>
												<div style={{ marginBottom: '1em' }}>
													<Header
														as="h3"
														color="teal">
														Jangan panik,
													</Header>
												</div>
												<div style={{ marginBottom: '1em' }}>
													<Header
														as="h2"
														color="teal">
														Kami akan bantu
													</Header>
												</div>
											</>
										)}
										{status === 'FORGET_HELP' && (
											<>
												<div style={{ marginBottom: '1em' }}>
													<Header as="h3">Bantuan datang,</Header>
												</div>
												<div style={{ marginBottom: '1em' }}>
													<Header as="h2">
														Kami kirim bantuan ke surel Anda
													</Header>
												</div>
											</>
										)}
										{status !== 'FORGET_HELP' && (
											<Form>
												<Form.Field required={status !== 'FORGET'}>
													<label className="teal">Kode Sekolah/ Lembaga</label>
													<Form.Input
														name="kode_sekolah"
														disabled={isLoading}
														placeholder="contoh : impuls"
														value={this.state.kode_sekolah}
														onChange={this.handleKodeSekolah}
													/>
													{!_.isEmpty(formStatusKode) && (
														<div style={{ marginBottom: '1em' }}>
															<Message {...formStatusKode.props} />
														</div>
													)}
												</Form.Field>
											</Form>
										)}
										{status !== 'FORGET_HELP' && <Divider />}
										{status === 'LOGIN' && (
											<LoginForm
												kode_sekolah={this.state.kode_sekolah}
												formStatusKode={{ ..._.cloneDeep(formStatusKode) }}
												setStatusKode={this.handleValidasiKodeSekolah}
												setStatus={this.handleStatus}
											/>
										)}
										{(status === 'FORGET' || status === 'FORGET_HELP') && (
											<ForgetForm
												kode_sekolah={this.state.kode_sekolah}
												formStatusKode={{ ..._.cloneDeep(formStatusKode) }}
												setStatusKode={this.handleValidasiKodeSekolah}
												setStatus={this.handleStatus}
											/>
										)}

										<div style={{ marginTop: '2em' }}>
											<Suggest />
										</div>
									</MainHolder>
								</Right>
								<Brand>
									<Footer
										fontSize=".65em"
										widthImage="36"
										fontColor="white"
										paddingContainer="0"
									/>
								</Brand>
							</>
						) : (
							<>
								<Left style={{ maxWidth: '350px' }}>
									<h2 style={{ margin: 0 }}>Selamat datang di</h2>
									<h1 style={{ margin: 0 }}>
										Impuls Computer Based Test (ICBT)
									</h1>
									<Brand>
										<Footer
											fontSize=".65em"
											widthImage={36}
											fontColor="white"
											paddingContainer="0"
										/>
									</Brand>
									<Divider />
									<div style={{ fontWeight: 500 }}>
										{'Informasi terbaru fitur ICBT dapat dibaca '}
										<Link
											style={{
												color: '#ffffff',
												fontWeight: 800,
												textDecoration: 'underline',
											}}
											onClick={() =>
												window.open('https://impuls.id/pos/kategori/icbt')
											}>
											di sini.
										</Link>
									</div>
									<Divider />
									<div style={{ fontWeight: 500 }}>
										Khusus peserta bisa mengerjakan memalui aplikasi ICBT Khusus
										Peserta
									</div>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'start',
											gap: '1rem',
											marginTop: '1rem',
										}}>
										<Link
											onClick={() =>
												window.open(
													'https://play.google.com/store/apps/details?id=com.impuls.icbt',
												)
											}>
											<img
												src={playStoreLogo}
												alt="playstore-logo"
												style={{ height: '35px' }}
											/>
										</Link>
										<Link
											onClick={() =>
												window.open(
													'https://apps.apple.com/id/app/icbt-untuk-peseta/id6499256814',
												)
											}>
											<img
												src={appStoreLogo}
												alt="playstore-logo"
												style={{ height: '35px' }}
											/>
										</Link>
									</div>
								</Left>
								<Right style={{ width: '363px' }}>
									<MainHolder>
										{status === 'FORGET' && (
											<>
												<div style={{ marginBottom: '1em' }}>
													<Header
														as="h3"
														color="teal">
														Jangan panik,
													</Header>
												</div>
												<div style={{ marginBottom: '1em' }}>
													<Header
														as="h2"
														color="teal">
														Kami akan bantu
													</Header>
												</div>
											</>
										)}
										{status === 'FORGET_HELP' && (
											<>
												<div style={{ marginBottom: '1em' }}>
													<Header
														color="teal"
														as="h3">
														Bantuan datang,
													</Header>
												</div>
												<div style={{ marginBottom: '1em' }}>
													<Header
														color="teal"
														as="h2">
														Kami kirim bantuan ke surel Anda
													</Header>
												</div>
											</>
										)}
										{status !== 'FORGET_HELP' && (
											<Form>
												<Form.Field required={status !== 'FORGET'}>
													<label className="teal">Kode Sekolah/ Lembaga</label>
													<Form.Input
														name="kode_sekolah"
														disabled={isLoading}
														placeholder="contoh : impuls"
														value={this.state.kode_sekolah}
														onChange={this.handleKodeSekolah}
													/>
													{!_.isEmpty(formStatusKode) && (
														<div style={{ marginBottom: '1em' }}>
															<Message {...formStatusKode.props} />
														</div>
													)}
												</Form.Field>
											</Form>
										)}
										{status !== 'FORGET_HELP' && <Divider />}
										{status === 'LOGIN' && (
											<LoginForm
												kode_sekolah={this.state.kode_sekolah}
												formStatusKode={{ ..._.cloneDeep(formStatusKode) }}
												setStatusKode={this.handleValidasiKodeSekolah}
												setStatus={this.handleStatus}
											/>
										)}
										{(status === 'FORGET' || status === 'FORGET_HELP') && (
											<ForgetForm
												kode_sekolah={this.state.kode_sekolah}
												formStatusKode={{ ..._.cloneDeep(formStatusKode) }}
												setStatusKode={this.handleValidasiKodeSekolah}
												setStatus={this.handleStatus}
											/>
										)}

										<div style={{ marginTop: '2em' }}>
											<Suggest />
										</div>
									</MainHolder>
								</Right>
							</>
						)
					}
				</Media>
			</Layout>
		);
	}
}

LoginOpen.contextType = UserContext;

export default LoginOpen;
