import _ from 'lodash';
import L from 'react-loadable';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading = () => (
	<Dimmer active={true}>
		<Loader />
	</Dimmer>
);

const defaultConfig = { timeout: 10000, loading: Loading };

const Loadable = (toBeLoad) =>
	L(_.assign({}, defaultConfig, { loader: toBeLoad }));

export default Loadable;
