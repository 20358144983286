import Loadable from '../components/Common/Loadable';

import Pengaturan from '../views/Pengaturan/Index';

const Profil = Loadable(() => import('../views/Profil'));

// const PrintToPDF = Loadable(() => import("../templates/BankSoal/DetilSoal/PrintToPDF"));

const DaftarHasil = Loadable(
	() => import('../views/Hasil/DaftarHasil/DaftarHasil'),
);
const InputIsian = Loadable(() => import('../views/Hasil/InputNilai/Isian'));
const InputUraian = Loadable(() => import('../views/Hasil/InputNilai/Uraian'));
const DetilNilai = Loadable(
	() => import('../views/Hasil/DetilNilai/DetilNilai'),
);
const DaftarHasilPaket = Loadable(
	() => import('../views/Hasil/DaftarHasil/DaftarHasilPaket'),
);
const DetilNilaiPaket = Loadable(
	() => import('../views/Hasil/DetilNilai/DetilNilaiPaket'),
);
const DetilNilaiPeserta = Loadable(
	() => import('../views/Hasil/DetilNilaiPeserta/DetilNilaiPeserta'),
);

const DetilSesi = Loadable(() => import('../views/Jadwal/DetilSesi/DetilSesi'));
const DetilSoalSnapshot = Loadable(
	() => import('../views/Jadwal/DetilSoalSnapshot/DetilSoalSnapshot'),
);
const JadwalBaru = Loadable(
	() => import('../views/Jadwal/JadwalBaru/JadwalBaru'),
);
const DetilJadwal = Loadable(
	() => import('../views/Jadwal/DetilJadwal/DetilJadwal'),
);
const DaftarJadwal = Loadable(
	() => import('../views/Jadwal/DaftarJadwal/DaftarJadwal'),
);
const MonitorJadwal = Loadable(
	() => import('../views/Jadwal/MonitorJadwal/MonitorJadwal'),
);

const DetilPaket = Loadable(
	() => import('../views/BankSoal/DetilPaket/DetilPaket'),
);
const DaftarPaket = Loadable(
	() => import('../views/BankSoal/DaftarPaket/DaftarPaket'),
);
const DaftarPaketBaru = Loadable(
	() => import('../views/BankSoal/DaftarPaketBaru/DaftarPaketBaru'),
);

const DetilSoal = Loadable(
	() => import('../views/BankSoal/DetilSoal/DetilSoal'),
);

const DaftarMateri = Loadable(
	() => import('../views/MateriBelajarGuru/DaftarMateri/DaftarMateri'),
);
const MateriBaru = Loadable(
	() => import('../views/MateriBelajarGuru/MateriBaru/MateriBaru'),
);
const DetilMateri = Loadable(
	() => import('../views/MateriBelajarGuru/DetilMateri/DetilMateri'),
);

const listRoute = [
	{ path: ['/profil'].join('/'), component: Profil },

	{ path: ['/jadwal'].join('/'), component: DaftarJadwal },
	{ path: ['/jadwal', 'baru'].join('/'), component: JadwalBaru },
	{ path: ['/jadwal', 'monitor-sesi'].join('/'), component: MonitorJadwal },
	{ path: ['/jadwal', ':jadwal_id'].join('/'), component: DetilJadwal },
	{
		path: ['/jadwal', ':jadwal_id', 'snapshot'].join('/'),
		component: DetilSoalSnapshot,
		header: false,
	},
	{
		path: ['/jadwal', ':jadwal_id', ':sesi_id'].join('/'),
		component: DetilSesi,
	},

	{ path: ['/bank-soal'].join('/'), component: DaftarPaket },
	{ path: ['/bank-soal', 'baru'].join('/'), component: DaftarPaketBaru },
	{ path: ['/bank-soal', ':paket_id'].join('/'), component: DetilPaket },
	{ path: ['/bank-soal', 'soal', ':paket_id'].join('/'), component: DetilSoal },
	// {	path: ['/bank-soal', 'cetak-soal',':paket_id'].join('/'), component: PrintToPDF },
	{
		path: ['/bank-soal', ':paket_id', 'soal'].join('/'),
		component: DetilSoal,
		header: false,
	},

	{ path: ['/hasil-ujian'].join('/'), component: DaftarHasil },
	{ path: ['/hasil-ujian', 'paket'].join('/'), component: DaftarHasilPaket },
	{ path: ['/hasil-ujian', ':ujian_id'].join('/'), component: DetilNilai },
	{
		path: ['/hasil-ujian', ':ujian_id', 'isian'].join('/'),
		component: InputIsian,
	},
	{
		path: ['/hasil-ujian', ':ujian_id', 'uraian'].join('/'),
		component: InputUraian,
	},
	{
		path: ['/hasil-ujian', 'paket', ':ujian_id'].join('/'),
		component: DetilNilaiPaket,
	},
	{
		path: ['/hasil-ujian', 'murid', ':lembar_jawab_id'].join('/'),
		component: DetilNilaiPeserta,
	},

	{ path: ['/materi-belajar'].join('/'), component: DaftarMateri },
	{ path: ['/materi-belajar', 'baru'].join('/'), component: MateriBaru },
	{ path: ['/materi-belajar', ':materi_id'].join('/'), component: DetilMateri },

	{
		path: ['/pengaturan', 'akun-murid'].join('/'),
		component: Pengaturan.DaftarMurid,
	},
	{
		path: ['/pengaturan', 'akun-murid', ':murid_id'].join('/'),
		component: Pengaturan.DetilAkunMurid,
	},
	{
		path: ['/pengaturan', 'akun-karyawan'].join('/'),
		component: Pengaturan.DaftarKaryawan,
	},
	{
		path: ['/pengaturan', 'akun-karyawan', ':karyawan_id'].join('/'),
		component: Pengaturan.DetilAkunKaryawan,
	},
	// {	path: ['/pengaturan', 'statistik-penggunaan'].join('/'), component: Pengaturan.StatistikPenggunaan },
	{
		path: ['/pengaturan', 'daftar-mapel'].join('/'),
		component: Pengaturan.DaftarMapel,
	},
	// {	path: ['/pengaturan', 'daftar-mapel', ':mapel_id'].join('/'), component: Pengaturan.DetilMapel },
	{
		path: ['/pengaturan', 'identitas-sekolah'].join('/'),
		component: Pengaturan.IdentitasSekolah,
	},
	{
		path: ['/pengaturan', 'daftar-kelas'].join('/'),
		component: Pengaturan.DaftarKelas,
	},
	{
		path: ['/pengaturan', 'daftar-kelas', 'tambah-kelas'].join('/'),
		component: Pengaturan.TambahKelas,
	},
	{
		path: ['/pengaturan', 'daftar-kelas', ':kelas_id'].join('/'),
		component: Pengaturan.DetilKelas,
	},
	// { path: ['/pengumuman'].join('/'), component: Pengumuman.DaftarPengumumanView },
	// { path: ['/pengumuman', ':id'].join('/'), component: Pengumuman.DetilPengumumanView }
];

export default listRoute;
