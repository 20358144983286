import Loadable from '../components/Common/Loadable';

const DaftarUjian = Loadable(
	() => import('../views/Ujian/DaftarUjian/DaftarUjian'),
);
const UjiTunggu = Loadable(() => import('../views/Ujian/UjiTunggu/UjiTunggu'));
const PerSoal = Loadable(() => import('../views/Ujian/UjiKerja/PerSoal'));
const HasilUjian = Loadable(
	() => import('../views/Ujian/HasilUjian/HasilUjian'),
);

const ProfilMurid = Loadable(() => import('../templates/Profil/ProfilMurid'));

const DaftarMapel = Loadable(
	() => import('../views/MateriBelajarMurid/DaftarMapel/DaftarMapel.jsx'),
);
const ListMateri = Loadable(
	() => import('../views/MateriBelajarMurid/ListMateri/ListMateri.jsx'),
);
const DetilMateri = Loadable(
	() => import('../views/MateriBelajarMurid/DetilMateri/DetilMateri.jsx'),
);

export default [
	{ path: ['/profil'].join('/'), component: ProfilMurid },

	{ path: ['/ujian'].join('/'), component: DaftarUjian },
	{
		path: ['/ujian', ':sesi_id'].join('/'),
		component: UjiTunggu,
		header: false,
	},
	{
		path: ['/ujian', 'soal', ':sesi_id'].join('/'),
		component: PerSoal,
		header: false,
	},
	// {	path: ['/ujian', 'ikhtisar', ':sesi_id'].join('/'), component: Ikhtisar, header:false },
	{
		path: ['/ujian', 'hasil', ':sesi_id'].join('/'),
		component: HasilUjian,
		header: false,
	},

	{ path: ['/mapel'].join('/'), component: DaftarMapel },
	{ path: ['/mapel', ':mapel_id', 'materi'].join('/'), component: ListMateri },
	{
		path: ['/mapel', ':mapel_id', 'materi', ':materi_id'].join('/'),
		component: DetilMateri,
	},
];
