import _ from 'lodash';
// import styled from 'styled-components';
import React, { Component } from 'react';
import { Form, Icon, Message, Popup } from 'semantic-ui-react';

import { login } from '../../fetcher';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../context';
import styled from 'styled-components';
// import { FORGET_FORM } from '../../helpers/constant';

const ForgetPassword = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	position: absolute;

	> span {
		font-weight: 400;
		font-size: 0.9em;
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
`;

const PopupContentContainer = styled.div`
	display: inline-block;
	position: absolute;
	top: -0.4em;
`;

class LoginSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formStatus: {},
			formValue: {},
			isLoading: false,
			data: { username: 'impuls', password: 'password' },
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(e, props) {
		const { formValue } = this.state;
		const { name, value } = props;
		this.setState({ formStatus: false });
		return this.setState({
			formValue: _.assign({}, formValue, { [name]: value }),
		});
	}

	async handleSubmit() {
		const { kode_sekolah, setStatusKode } = this.props;
		this.setState({ isLoading: true });
		setStatusKode({ ..._.cloneDeep(this.props.formStatusKode) }, true);
		const session = this.context;
		const { formValue } = this.state;

		const response = await login({
			...formValue,
			sekolah_kode: _.toLower(kode_sekolah),
		});

		let formStatus = {};
		let formStatusKode = {};
		if (response.status_code === 200) {
			await session.checkUser();
			return <Redirect to="/test" />;
		} else {
			formStatus = { status: 'error', props: {} };
			formStatusKode = { status: 'error', props: {} };
			switch (response.message) {
				case 'Username and Password combination is invalid': {
					formStatus.props = {
						negative: true,
						size: 'tiny',
						header: 'Login Gagal',
						content:
							'Kombinasi username dan password yang Anda masukan tidak sesuai',
					};
					formStatusKode = {};
					break;
				}
				case 'Account is disabled': {
					formStatus.props = {
						negative: true,
						size: 'tiny',
						header: 'Login Gagal',
						content:
							'Saat ini akun Anda berstatus nonaktif. Apabila Anda ingin mengaktifkannya kembali, hubungi Admin sekolah/lembaga',
					};
					formStatusKode = {};
					break;
				}
				case 'Sekolah: Document not found': {
					formStatus = {};
					formStatusKode.props = {
						negative: true,
						size: 'tiny',
						header: 'Kode Sekolah/ Lembaga Tidak Ditemukan',
						content:
							'Anda dapat menanyakan kode tersebut kepada Administrator masing-masing sekolah/ lembaga',
					};
					break;
				}
				default:
			}
		}

		await setStatusKode(formStatusKode, false);

		this.setState({ isLoading: false, formStatus, formStatusKode });
	}

	render() {
		const { formValue, formStatus, isLoading } = this.state;

		const { setStatus, kode_sekolah } = this.props;

		const username = _.get(formValue, 'username', '');
		const password = _.get(formValue, 'password', '');

		const disabledButton =
			_.isEmpty(password) || _.isEmpty(username) || _.isEmpty(kode_sekolah);

		return (
			<Form>
				<Form.Field
					style={{ position: 'relative' }}
					required>
					<label style={{ display: 'inline-block', marginRight: '.4em' }}>
						Username
					</label>
					{
						<Popup
							wide
							size="tiny"
							horizontalOffset={10}
							content={`Guru/ karyawan menggunakan username, sedangkan murid menggunakan Nomor Induk/ NIS`}
							trigger={
								<PopupContentContainer>
									<Icon
										circular
										inverted
										size="mini"
										name="info"
										color="orange"
									/>
								</PopupContentContainer>
							}
						/>
					}
					<Form.Input
						disabled={isLoading}
						name="username"
						placeholder="Guru : username; Murid : Nomor Induk"
						value={username}
						onChange={this.handleChange}
					/>
				</Form.Field>
				<Form.Field required>
					<ForgetPassword style={{ fontWeight: '400', color: 'black' }}>
						<span onClick={() => setStatus('FORGET')}>Lupa Password ?</span>
					</ForgetPassword>
					<label className="teal">Password</label>
					<Form.Input
						disabled={isLoading}
						name="password"
						type="password"
						placeholder="Masukkan password / kata sandi akun Anda"
						value={password}
						onChange={this.handleChange}
					/>
				</Form.Field>
				{_.get(formStatus, 'props.negative', false) && (
					<div style={{ marginBottom: '1em' }}>
						<Message
							negative
							{..._.omit(_.get(formStatus, 'props', {}), ['error'])}
						/>
					</div>
				)}
				<Form.Button
					color="teal"
					type="submit"
					floated="right"
					disabled={disabledButton || isLoading}
					loading={isLoading}
					onClick={this.handleSubmit}>
					Masuk <Icon name="arrow right" />
				</Form.Button>
			</Form>
		);
	}
}

LoginSection.contextType = UserContext;

export default LoginSection;
