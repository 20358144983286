import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyBUNp-zsZxi0TZUgxXoQpCSfG5OgbrbWWE',
	authDomain: 'impuls-id.firebaseapp.com',
	databaseURL:
		'https://impuls-id-platform.asia-southeast1.firebasedatabase.app',
	projectId: 'impuls-id',
	storageBucket: 'impuls-id.appspot.com',
	messagingSenderId: '9198280399',
	appId: '1:9198280399:web:5fe8486d3e794ea4a721ba',
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
